<template>
	<div class="container-fluid">
		<br />
			<div class="col-sm-12 text-right">
				<h3>Bienvenido {{ $auth.getUser().nombre }}</h3>
			</div>
		<br />
		<div class="d-flex flex-wrap">
			<div class="col-md-4" v-for="app in $auth.getApp()" :key="app.id">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img :src="'https://static.bienparabien.com/constelacion/img/'+$helper.normalizar(app.aplicacion+'.svg')" :alt="app.aplicacion" width="80px" />
						</div>
						<h4 class="card-title">{{ app.aplicacion }}</h4>
						<p class="card-subtitle text-muted">{{ app.descripcion }}</p>
						<router-link class="btn complementary-btn" :to="{name: $helper.normalizar(app.aplicacion)}">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		created: function() {
			// this.$log.info(this.$auth.getApp());
		}
		,mounted() {
			this.$store.commit('setMenu',[]);
		}
		,methods: {
			getUrl: function(url) {
				return require('@/apps/constelacion/assets/img/cards/'+url);
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/general-styles/components/card.scss';
</style>